import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiError } from '../model/api-error';
import { Engine } from '../model/engine';
import { EntityId, isEntityIdentifier, isPartnerId, isSecondLifeId } from '../model/entity-id';
import { EntityIdentifier } from '../model/entity-identifier';
import { GeoProcessUnit } from '../model/geo-process-unit';
import { GeographicArea, toTierConcat } from '../model/geographic-area';
import { NrtModel } from '../model/nrt-model';
import { SecondLifeId } from '../model/second-life-id';
import { User } from '../model/user';
import { Item } from '../model/item';
import {EntityIdNotFound, TimelinesToDisplayReset, UserSelect} from '../state/actions';
import { QueryContext } from '../state/model/entity-geo-area';
import { Type } from '../model/type';
import { env } from './env';
import { NotificationsService } from './notifications.service';
import { GeoParametersCategories } from '../model/parameters';
import { switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { EntityIdSelect } from '../state/actions';
import { TypeSelect } from '../state/actions';
import {UserMetricService} from './user-metric.service';
import { PartnerId } from '../model/partner-id';


@Injectable({
  providedIn: 'root'
})
export class RpcService {
  static showChildrenGeoArea = false;

  constructor(private http: HttpClient,
              private store: Store,
              private oauthService: OAuthService,
              private notificationsService: NotificationsService,
              private router: Router,
              private userMetricService : UserMetricService) {

    oauthService.configure({
      issuer: env.fedIdUrl,
      redirectUri: window.location.origin + '/',
      clientId: env.clientId,
      dummyClientSecret: env.clientSecret,
      responseType: 'code',
      scope: 'openid profile'
    });
    // Send the current state
    oauthService.loadDiscoveryDocumentAndLogin({ state: window.location.search })
    .then((isAuth) => {
      if (isAuth) {
        if (oauthService.state && oauthService.state !== window.location.search) {
          this.router.navigateByUrl('/' + decodeURIComponent(oauthService.state)
          );
        }
        userMetricService.setUserIsAuthenticated(isAuth);
      }

    })
    .then(() => {
      this.getCurrentUser().subscribe(user => this.store.dispatch(new UserSelect(user)))
    })
    .catch(e => notificationsService.error('Authentication issue', e.toString()));
  }

  getGeoHierarchy(geoId: number): Observable<GeographicArea[]> {
    const params = new HttpParams().set('id', String(geoId));
    return this.http.get<GeographicArea[]>(env.apiUrl + '/v1/support/simulation/geographicArea/hierarchy', { params })
    .pipe(catchError(e => this.handleError(e)));
  }

  searchGeographicArea(query: string): Observable<GeographicArea[]> {
    const params = new HttpParams().set('query', query);
    return this.http.get<GeographicArea[]>(env.apiUrl + '/v1/support/simulation/geographicArea', { params })
    .pipe(catchError(e => this.handleError(e)));
  }

  searchItems(type: string, code: string): Observable<Item[]> {
    const params = new HttpParams().set('type', type).set('code', code);
    return this.http.get<Item[]>(env.apiUrl + '/v1/support/products_referential/items', { params });
  }

  fetchEngine(entityGeo: QueryContext): Observable<NrtModel[]> {
    switch (entityGeo.engine) {
      case (Engine.Marketplace):
        return this.fetchMarketplaceEngine(entityGeo);
      case Engine.Secondlife:
        return this.fetchSecondLifeEngine(entityGeo, false);
      case Engine.Retail:
        return this.fetchRetailEngine(entityGeo);
      default:
        return this.fetchRetailEngine(entityGeo);
    }
  }

  fetchMarketplaceEngine(entityGeo: QueryContext) {
    if (!isPartnerId(entityGeo.entityId)) {
      return null;
    }
    const partnerId = entityGeo.entityId.partner_id;
    const params = new HttpParams().set('tiers_concat', toTierConcat(entityGeo.geoArea))
    .set('entity_code', partnerId)
    // debug + show_children can produce multiple Go of data, so disable debug if show_children is activated
    .set('debug', String(!environment.production && !RpcService.showChildrenGeoArea));

    return this.http.get<NrtModel[]>(env.apiUrl + '/v3/partners/simulation/prices', addHeader(params, entityGeo.geoArea))
    .pipe(catchError(e => this.handleTimelinesError(e)));
  }

  fetchRetailEngine(entityGeo: QueryContext) {
    if (!isEntityIdentifier(entityGeo.entityId)) {
      return null;
    }
    const modelCode = entityGeo.entityId.code;
    const params = new HttpParams().set('tiers_concat', toTierConcat(entityGeo.geoArea))
    .set('entity_code', modelCode.toString())
    .set('recompute', 'false')
    .set('entity_type', entityGeo.type === Type.Item ? 2 : 1)
    // debug + show_children can produce multiple Go of data, so disable debug if show_children is activated
    .set('debug', String(!environment.production && !RpcService.showChildrenGeoArea))
    .set('show_children', String(RpcService.showChildrenGeoArea));


    const originalRequest = this.http.get<NrtModel[]>(env.apiUrl + '/v1/support/simulation/prices/engine', addHeader(params, entityGeo.geoArea)).pipe(
      catchError((error) => {
        return of([], error);
      })
    );

    const updatedParams = params.set('entity_type', entityGeo.type === Type.Item ? '1' : '2');
    const modifiedRequest = this.http.get<NrtModel[]>(env.apiUrl + '/v1/support/simulation/prices/engine', addHeader(updatedParams, entityGeo.geoArea)).pipe(
      catchError(error => {
        return of([], error);
      })
    );

    return forkJoin([originalRequest, modifiedRequest]).pipe(
      switchMap(responses => {
        const [originalResponse, modifiedResponse] = responses;
        const successfulResponse = originalResponse.length > 0 ? originalResponse : modifiedResponse;
        const successfulParams = originalResponse.length > 0 ? params : updatedParams;
        const error = originalResponse.error || modifiedResponse.error;

        if (successfulResponse.length > 0) {

          let typeParam = (Number(successfulParams.get('entity_type')) == 2 ) ? Type.Item : Type.Model;

          if (entityGeo.type !== typeParam) {
            this.store.dispatch(new TypeSelect(typeParam));
            this.store.dispatch(new EntityIdSelect(<EntityIdentifier> { code: Number(modelCode), type: typeParam.toString() }));
          }

          return of(successfulResponse);
        } else {
          const httpError = new HttpErrorResponse({
            error: error,
            status: 400
          });
          return this.handleError(httpError);
        }
      }),
      catchError(error => {
        return this.handleError(error);
      })
    );
  }

  fetchParametersForGeoArea(entityGeo: GeographicArea): Observable<GeoParametersCategories> {
    return this.http.get<GeoParametersCategories>(env.apiUrl + '/v1/support/geographic_areas/tiers/' + toTierConcat(entityGeo)
      + '/parameters-categories')
      .pipe(catchError(e => this.handleError(e)));
  }

  fetchSecondLifeEngine(entityGeo: QueryContext, debug: boolean) {
    if (!isSecondLifeId(entityGeo.entityId)) {
      return null;
    }
    const params = new HttpParams().set('tiers_concat', toTierConcat(entityGeo.geoArea))
    .set('second_life_id', entityGeo.entityId.second_life_id.toString())
    .set('debug', debug)

    return this.http.get<NrtModel[]>(env.apiUrl + '/v1/support/simulation/second_life/engine', addHeader(params, entityGeo.geoArea))
    .pipe(catchError(e => this.handleError(e)));
  }

  computeEngine(modelProcessUnit: GeoProcessUnit, context: QueryContext): Observable<NrtModel[]> {
    //We just want to refresh the timeline with the debug enabled.
    //Could be an adjustement for other engine in the future
    if(context.engine == Engine.Secondlife)
      return this.fetchSecondLifeEngine(context, true);

    this.store.dispatch(new EntityIdNotFound(modelProcessUnit == null));

    return modelProcessUnit == null ? EMPTY :
      this.http.post<NrtModel[]>(env.apiUrl + '/v1/support/simulation/prices/engine?debug=true', modelProcessUnit)
      .pipe(catchError(e => this.handleError(e)));
  }


  replayPrice(entityId: EntityId, geoArea: GeographicArea, date: Date, forceReplay: boolean) {
    if(isSecondLifeId(entityId)){
      return this.replaySecondLifePrice(<SecondLifeId>entityId, geoArea, false);
    } else if(isPartnerId(entityId)) {
      return this.replayPartnerPrice(<PartnerId>entityId, geoArea);
    }

    return this.replayRetailPrice(<EntityIdentifier> entityId, geoArea, date, forceReplay);
  }

  replayRetailPrice(entityId: EntityIdentifier, geoArea: GeographicArea, date: Date, forceReplay: boolean) {
    let entityTypeAsValue = entityId.type == 'MODEL' ? 1 : 2;

    let params = new HttpParams()
    .set('tiers_concat', toTierConcat(geoArea))
    .set('entity_code', entityId.code.toString())
    .set('entity_type', entityTypeAsValue.toString())
    .set('exclude_life_stage_4', 'false')
    .set('force_replay', forceReplay.toString());
    if (date != null) {
      params = params.set('replay_date', date.toISOString());
    }
    return this.http.post(env.apiUrl + '/v1/support/prices/replay/retail/direct', EMPTY, addHeader(params, geoArea))
    .pipe(catchError(e => this.handleError(e)));
  }

  replaySecondLifePrice(secondLifeId: SecondLifeId, geoArea: GeographicArea, force: boolean) {

    let params = new HttpParams()
    .set('tiers_concat', toTierConcat(geoArea))
    .set('secondLifeId', secondLifeId.second_life_id)
    .set('force', force)

    return this.http.post(env.apiUrl + '/v1/support/prices/replay/secondlife', EMPTY, addHeader(params, geoArea))
    .pipe(catchError(e => this.handleError(e)));
  }

  replayPartnerPrice(partnerId: PartnerId, geoArea: GeographicArea) {

    let params = new HttpParams()
    .set('channel', geoArea.sales_organization_code);

    let body = [partnerId.partner_id];

    return this.http.post(env.apiUrl + '/v1/support/prices/replay/partner', body, addHeader(params, geoArea))
    .pipe(catchError(e => this.handleError(e)));
  }

  forceReplayPrice(entityId: EntityId, geoArea: GeographicArea) {
    if(isSecondLifeId(entityId)){
      return this.replaySecondLifePrice(<SecondLifeId>entityId, geoArea,true);
    }

    return this.forceReplayRetailPrice(<EntityIdentifier> entityId, geoArea);
  }

  resendInKafka(entityId: EntityIdentifier, geoArea: GeographicArea) {
    let entityTypeAsValue = entityId.type == 'MODEL' ? 1 : 2;

    var params = new HttpParams()
    .set('tiers', toTierConcat(geoArea));

    return this.http.post(env.apiUrl + '/v1/support/prices/kafka/push',
      {
        'tiers': new Array(toTierConcat(geoArea)),
        'model_codes': (entityId.type == 'MODEL'? [entityId.code.toString()] : []),
        'item_codes': (entityId.type == 'ITEM'? [entityId.code.toString()] : []),
      }, addHeader(params, geoArea))
    .pipe(catchError(e => this.handleError(e)));
  }

  forceReplayRetailPrice(entityId: EntityIdentifier, geoArea: GeographicArea) {
    let entityTypeAsValue = entityId.type == 'MODEL' ? 1 : 2;

    const params = new HttpParams()
    .set('tiers_concat', toTierConcat(geoArea))
    .set('entity_code', entityId.code.toString())
    .set('entity_type', entityTypeAsValue.toString());

    return this.http.post(env.apiUrl + '/v1/support/prices/replay/sale_at_loss', EMPTY, addHeader(params, geoArea))
    .pipe(catchError(e => this.handleError(e)));
  }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>(env.apiUrl + '/v1/support/users/me', {});
  }

  fetchPriceAvailability(sku: string, tier: string): Observable<string[]> {
    const params = new HttpParams().set('entity_code', sku.toLowerCase()).set('tiers_concat', tier);
    return this.http.get<string[]>(env.apiUrl + '/v3/partners/simulation/availability', { params });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 403) {
      this.notificationsService
      .warn('You are not authorized to use those APIs.', 'Please provide your account name at masterprice_technical@decathlon.net');
    }
    const apiError = error.error as ApiError;
    if (Array.isArray(apiError.error_description)) { // message is well formed, we can extract the description
      this.notificationsService.notifyApiError(apiError);
    } else {
      console.error(`Backend returned code ${error.status}, body was: ${JSON.stringify(apiError)}`);
      this.notificationsService.error('Something wrong happened...', `Backend returned code ${error.status}`);
    }
    return EMPTY;
  }

  private handleTimelinesError(error: HttpErrorResponse) {
    this.store.dispatch(new TimelinesToDisplayReset())
    return this.handleError(error);
  }
}

function addHeader(params: HttpParams, geoArea: GeographicArea): { headers: HttpHeaders, params: HttpParams } {
  return { params, headers: XTiersHeader(geoArea) };
}

function XTiersHeader(geoArea: GeographicArea): HttpHeaders {
  return new HttpHeaders().set('X-Tiers', toTierConcat(geoArea));
}

