import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { EntityId, isEntityIdentifier, isSecondLifeId, isPartnerId } from 'src/app/model/entity-id';
import { NotificationsService } from '../../api/notifications.service';
import { RpcService } from '../../api/rpc.service';
import { EntityIdentifier } from '../../model/entity-identifier';
import { GeographicArea } from '../../model/geographic-area';
import { SecondLifeId } from '../../model/second-life-id';
import { QueryContextState } from '../../state/action/state';
import { QueryContext } from '../../state/model/entity-geo-area';
import { currentModel, TimelinesToDisplay } from '../../state/model/timelines-to-display';
import { EngineState } from '../../state/action/state';
import { Engine } from 'src/app/model/engine';
import { PartnerId } from 'src/app/model/partner-id';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent implements OnInit {

  @Select(QueryContextState) state$: Observable<QueryContext>;
  @Select() timelines$: Observable<TimelinesToDisplay>;
  @Select(EngineState) engine$: Observable<Engine>;
  replayDate: Date;
  currentId: EntityId;
  partnerId: PartnerId;
  currentGeoArea: GeographicArea;

  constructor(private rpcService: RpcService, private notificationService: NotificationsService) {
  }

  isSecondLifeId(){
    return isSecondLifeId(this.currentId);
  }

  isEntityIdentifier(){
    return isEntityIdentifier(this.currentId);
  }

  isPartnerId(){
    return isPartnerId(this.partnerId);
  }

  replay(forceReplay: boolean) {

    this.rpcService.replayPrice(this.currentId, this.currentGeoArea, this.replayDate, forceReplay)
    .subscribe(() => {
      this.notificationService.success('Replay success', 'Replay has been accepted and processed');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      })
  }

  resendInKafka() {
    this.rpcService.resendInKafka(<EntityIdentifier>this.currentId, this.currentGeoArea)
    .subscribe(() => {
      this.notificationService.success('Timeline sent', 'Timeline has been resent in kafka topic');
    })
  }

  ngOnInit(): void {
    this.replayDate = new Date();
    this.timelines$.subscribe(timelines => {
      const nrtModel = currentModel(timelines);
      if (!!nrtModel) {
        const entityId = nrtModel?.input.product_process_units[0].entity_identifier;
        if (isEntityIdentifier(entityId)) {
          this.currentId = <EntityIdentifier> nrtModel.input.product_process_units[0].entity_identifier;
        } else if(isSecondLifeId(entityId)){
          this.currentId = <SecondLifeId> entityId;
        } else if(isPartnerId(entityId)){
          this.currentId = <PartnerId> entityId;
        }
        this.currentGeoArea = nrtModel.input.geographic_area;
      }
    });
  }
}
